import React from 'react'
import './about.css'
// import ME from '../../assets/me5.png'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {LuFolderInput} from 'react-icons/lu'

const About = () => {
  return (
    <section id='about'>
    <h5>Get To Know</h5>
    <h2>About Me</h2>

    <div className="container about__container">
      {/* <div className="about__me">
        <div className="about__me-image">
          <img src={ME} alt="About me" />
        </div>
      </div> */}

      <div className="about__content">
        <div className="about__cards">
          <article className='about__card'>
            <FaAward className='about__icon'/>
            <h5>Experience</h5>
            <small>4+ Years</small>
          </article>

          <article className='about__card'>
            <FiUsers className='about__icon'/>
            <h5>Clients</h5>
            <small>15+ Worldwide</small>
          </article>

          <article className='about__card'>
            <LuFolderInput className='about__icon'/>
            <h5>Projects</h5>
            <small>28+ Completed</small>
          </article>
        </div>
        <p>
        I am a seasoned Cloud & DevOps Engineer with over 4 years of experience in the field. 
        I have worked with various organizations helping them streamline their software development and IT operations processes. 
        I have a passion for automation, continuous integration, and continuous delivery. 
        I am committed to promoting best practices in DevOps, fostering collaboration between development and operations teams, 
        and driving efficiency throughout the software development lifecycle.
        </p>

        <a href="#contact" className='btn btn-primary about__button'>Let's Talk</a>
      </div>
    </div>
    </section>
  )
}

export default About