import React, { useRef } from 'react'
import './contact.css'
import {MdOutlineMarkEmailUnread} from 'react-icons/md'
// import {PiMessengerLogoBold} from 'react-icons/pi'
import {IoLogoWhatsapp} from 'react-icons/io'
import emailjs from 'emailjs-com'


const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_yf1dd0o', 'template_0ojktpi', form.current, 'aBCT8etDvcPFDBC_0')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
    e.target.reset()
  };

  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineMarkEmailUnread className='contact__option-icon' />
            <h4>Email</h4>
            <h5>hello@enyioman.com</h5>
            <a href="mailto:hello@enyioman.com" target="_blank" rel="noreferrer">Send a message</a>
          </article>
          {/* <article className="contact__option">
            <PiMessengerLogoBold className='contact__option-icon' />
            <h4>Messenger</h4>
            <h5>@enyioman</h5>
            <a href="http://m.me/enyioman" target="_blank" rel="noreferrer">Send a message</a>
          </article> */}
          <article className="contact__option">
            <IoLogoWhatsapp className='contact__option-icon' />
            <h4>WhatsApp</h4>
            <h5>+DM Me</h5>
            <a href="https://wa.me/2348103762554" target="_blank" rel="noreferrer">Send a message</a>
          </article>
        </div>

        {/* End of Contact Options Section */}

        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your Full Name' required />
          <input type="email" name='email' placeholder='Your email' required />
          <textarea name="message" rows="7" placeholder='Your Message Here' required></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact