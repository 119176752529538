import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/security.jpg'
import IMG2 from '../../assets/trigger.jpg'
import IMG3 from '../../assets/docker.jpg'
import IMG4 from '../../assets/serverless2.jpg'
import IMG5 from '../../assets/deployment.jpg'
import IMG6 from '../../assets/load1.jpg'


const data = [
  {
    id: 1,
    image: IMG1,
    title: 'DevSecOps Deployment of a Netflix-Clone App on AWS EKS',
    github: 'https://github.com/enyioman/DevSecOps-Project',
    demo: 'https://medium.com/@enyioman/implementing-devsecops-deploying-a-netflix-clone-app-on-aws-eks-6b1078344ace'
  },
  {
    id: 2,
    image: IMG2,
    title: 'Streamlining Workflows with AWS S3 Event Triggers',
    github: 'https://github.com/enyioman/lambda2S3',
    demo: 'https://medium.com/aws-tip/elevating-customer-experience-streamlining-workflows-with-aws-s3-event-triggers-c0e481dee275'
  },
  {
    id: 3,
    image: IMG3,
    title: 'Deploy a Docker Container on AWS ECS using Terraform',
    github: 'https://github.com/enyioman/terraform-docker-ecs',
    demo: 'https://medium.com/aws-tip/deploy-a-docker-container-on-aws-ecs-using-terraform-6ad99c7d0498'
  },
  {
    id: 4,
    image: IMG4,
    title: 'AWS Serverless CRUD Application With Python',
    github: 'https://gist.github.com/enyioman/c5fd558c46d3b70b4ce0506210ab677a#file-lambda_function-py',
    demo: 'https://medium.com/aws-tip/crud-application-using-api-gateway-lambda-dynamodb-and-python-84d486c87df4'
  },
  {
    id: 5,
    image: IMG5,
    title: '3-Tier App Deployment With Terraform',
    github: 'https://gitlab.com/enyioma/terraform-project18',
    demo: 'https://github.com/enyioman/darey.io_projects/blob/main/project19/project19.md'
  },{
    id: 6,
    image: IMG6,
    title: 'Load Balancer Solution With Nginx and SSL/TLS',
    github: 'https://github.com/enyioman/project10/blob/main/project10.md',
    demo: 'https://github.com/enyioman/project10/blob/main/project10.md'
  }
]

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {
          data.map(({id, image, title, github, demo}) => {
            return (
              <article key={id} className='portfolio__item'>
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
                <h3>{title}</h3>
              <div className="portfolio__item-cta">
              <a href={github} className='btn' target='_blank' rel="noreferrer">GitHub</a>
              <a href={demo} className='btn btn-primary' target='_blank' rel="noreferrer">Documentation</a>
            </div>
        </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Portfolio