import React from 'react'
import './experience.css'
import {BsPatchCheckFill} from 'react-icons/bs'

const Experience = () => {
  return (
    <section id='experience'>
      <h5>The Skills I Have</h5>
      <h2>My Experience</h2>

      <div className="container experience__container">
        <div className="experience__frontend">
          <h3>Cloud & DevOps Engineering</h3>
          <div className="experience__content">
          <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
                <h4>Cloud Platform</h4>
                <small className='text-light'>AWS, GCP</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
                <h4>Containerization/Orchestration</h4>
                <small className='text-light'>Docker, Kubernetes</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
                <h4>CI/CD</h4>
                <small className='text-light'>Jenkins, GitHub Actions, GitLab CI/CD, AWS CodePipeline</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
                <h4>Automation/Configuration Management</h4>
                <small className='text-light'>Ansible</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
                <h4>IAC</h4>
                <small className='text-light'>Terraform</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
                <h4>Scripting</h4>
                <small className='text-light'>Python, Bash</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
                <h4>Monitoring/Observation/Logging</h4>
                <small className='text-light'>Prometheus, Grafana, Elastic Stack, AWS CloudWatch, Sentry</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
                <h4>Test Automation/Quality Assurance</h4>
                <small className='text-light'>Selenium, JUnit, Sonarqube</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
                <h4>Security</h4>
                <small className='text-light'>OWASP, Trivy, Snyk</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
                <h4>Version Control</h4>
                <small className='text-light'>Git, GitHub, GitLab</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
                <h4>Databases</h4>
                <small className='text-light'>MySQL, Postgresql, MongoDB</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
                <h4>WebServers</h4>
                <small className='text-light'>Nginx, Apache</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
                <h4>Task Management/Collaboration</h4>
                <small className='text-light'>Jira, Trello, Slack</small>
              </div>
            </article>
          </div>
        </div>

         {/* SECOND SLIDE */}

        {/* <div className="experience__backend">
        <h3>Cloud Engineering</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
                <h4>Orchestration</h4>
                <small className='text-light'>Kubernetes (GKE) </small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
                <h4>Platform</h4>
                <small className='text-light'>AWS, GCP</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
                <h4>Automation</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
                <h4>IAC</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
                <h4>Scripting</h4>
                <small className='text-light'>Experienced</small>
              </div>
            </article>
          </div>
        </div> */}
      </div>
    </section>
  )
}

export default Experience