import React from 'react'
import './footer.css'
import {FaLinkedinIn} from 'react-icons/fa'
import {PiGithubLogoBold} from 'react-icons/pi'
import {BiLogoMedium} from 'react-icons/bi'

const Footer = () => {
  return (
    <footer>
      <a href="/#" className='footer__logo'>@enyioman</a>
      <ul className="permalinks">
        <li> <a href="/#">Home</a> </li>
        <li> <a href="#about">About</a> </li>
        <li> <a href="#experience">Experience</a> </li>
        {/* <li> <a href="#services">Services</a> </li> */}
        <li> <a href="#portfolio">Portfolio</a> </li>
        <li> <a href="#testimonials">Testimonials</a> </li>
        <li> <a href="#contact">Contact</a> </li>
      </ul>

      <div className="footer__socials">
        <a href="https://linkedin.com/in/enyioman" target='_blank' rel="noreferrer"><FaLinkedinIn /></a>
        <a href="https://github.com/enyioman" target='_blank' rel="noreferrer"><PiGithubLogoBold /></a>
        <a href="https://medium.com/@enyioman" target='_blank' rel="noreferrer"><BiLogoMedium /></a>
      </div>

      <div className="footer__copyright">
        <small>&copy; 2023</small>
      </div>
    </footer>
  )
}

export default Footer