import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithubSquare} from 'react-icons/fa'
import {FaMedium} from 'react-icons/fa'

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href="https://linkedin.com/in/enyioman" target='_blank' rel="noreferrer"><BsLinkedin /></a>
        <a href="https://github.com/enyioman" target='_blank' rel="noreferrer"><FaGithubSquare /></a>
        <a href="https://medium.com/@enyioman" target='_blank' rel="noreferrer"><FaMedium /></a>
    </div>
  )
}

export default HeaderSocials